<button mat-icon-button class="close-btn" type="button" mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>
<h1 class="main-title" translate>userEdit.header-msg</h1>
<!-- <h2 class="sub-title" translate>userEdit.general</h2> -->

<mat-tab-group mat-align-tabs="start" [selectedIndex]="tabIndex">
  <mat-tab [label]="'common.editProfileTab1' | translate">
    <div class="aveter-box">
      <div class="section-one">
        <img src="assets/img/avatar_1.png" width="140" height="140" />
      </div>
      <div class="section-two">
        <h4 translate>userEdit.loginSection</h4>
        <p>{{ user && user.firstName }} {{ user && user.lastName }}</p>

        <div *ngIf="user && user.oauth == false && user.oauthProvider == null">
          <h4 translate>userEdit.password</h4>
          **********
          <button mat-button color="primary" (click)="changePass()">{{ "userEdit.pwd-change-btn" | translate }}</button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="user">
      <div class="form-group row">
        <label class="col-sm-4 col-form-label" translate>userEdit.fName</label>
        <div class="col-sm-8">
          <input type="text" [(ngModel)]="user.firstName" class="form-control"
            placeholder="{{ 'userEdit.fName-placeholder' | translate }}" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label" translate>userEdit.lName</label>
        <div class="col-sm-8">
          <input type="text" [(ngModel)]="user.lastName" class="form-control"
            placeholder="{{ 'userEdit.lName-placeholder' | translate }}" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label" translate>userEdit.mobile</label>
        <div class="col-sm-8">
          <input type="text" [(ngModel)]="user.officeTel" class="form-control"
            placeholder="{{ 'userEdit.mobile-placeholder' | translate }}" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label" translate>userEdit.msg-signature</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" [(ngModel)]="user.signature"
            placeholder="{{ 'userEdit.msg-signature-placeholder' | translate }}" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label" translate>userEdit.timezone</label>
        <div class="col-sm-8">
          <!-- <input type="text" class="form-control" placeholder="{{ 'userEdit.timezone-placeholder' | translate }}" /> -->
          <mat-select class="custom-outline" [(ngModel)]="user.timeZone">
            <mat-toolbar>
              <mat-icon class="search-icon" [ngClass]="{
                'onArabic': langService.getCurrentLanguage() == 'ar',
                'search-icon2': langService.getCurrentLanguage() == 'ar'
              }">search</mat-icon>
              <input focus type="text" class="form-control search-bar" (keyup)="onKey($event.target)" />
            </mat-toolbar>
            <mat-option *ngFor="let time of displayTimezones" [value]="time.label">{{ time.label}}</mat-option>
          </mat-select>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label" translate>userEdit.itemsPerPage</label>
        <div class="col-sm-8">
          <mat-select class="custom-outline" [(ngModel)]="itemsPerPage">
            <mat-option value="10">10</mat-option>
            <mat-option value="20">20</mat-option>
            <mat-option value="50">50</mat-option>
            <mat-option value="100">100</mat-option>
          </mat-select>
        </div>
      </div>
    </ng-container>

    <div class="form-group align-right" *ngIf="user">

      <button mat-raised-button color="accent" type="submit" class="btn cs-secondary-bt" mat-dialog-close>{{
        "userEdit.cancel-btn" | translate }}</button>
      <button mat-raised-button color="primary" type="submit" class="btn cs-primary-btn" (click)="saveChanges()">{{
        "userEdit.save-btn" | translate}}</button>
    </div>

  </mat-tab>
  <mat-tab [label]="'common.editProfileTab2' | translate" *ngIf="user && user.role.toLowerCase() == 'admin'">
    <ng-container *ngIf="company">
      <div class="form-group row mt-3">
        <label class="col-sm-4 col-form-label" translate>userEdit.companyName</label>
        <div class="col-sm-8">
          <input type="text" [(ngModel)]="company.organisationName" class="form-control"
            placeholder="{{ 'userEdit.companyName-placeholder' | translate }}" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label" translate>userEdit.contactName</label>
        <div class="col-sm-8">
          <input type="text" [(ngModel)]="company.contactName" class="form-control"
            placeholder="{{ 'userEdit.contactName-placeholder' | translate }}" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label" translate>userEdit.contactMobile</label>
        <div class="col-sm-8">
          <input type="text" [(ngModel)]="company.contactMobile" class="form-control"
            placeholder="{{ 'userEdit.contactMobile-placeholder' | translate }}" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label" translate>userEdit.email</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" [(ngModel)]="company.contactEmail"
            placeholder="{{ 'userEdit.email-placeholder' | translate }}" />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-sm-4 col-form-label" translate>userEdit.defaultCountryCode</label>
        <div class="col-sm-8">
          <mat-form-field class="w-100 bg-white" appearance="fill">
            <input type="text"
              class="form-control bg-white"
              [ngClass]="{'border-danger': (filteredCountries | async).length == 0}"
              [placeholder]="'userEdit.defaultCountryCode-placeholder' | translate"
              matInput
              [formControl]="countries"
              [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <ng-container *ngFor="let item of filteredCountries | async">

                <mat-option value="{{item.country}} - {{item.code}} - {{item.currency}}">{{item.country}} - {{item.code}} - {{item.currency}}</mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>

        </div>
      </div>

      <!-- <div class="form-group row">
        <label class="col-sm-4 col-form-label" translate>userEdit.defaultCurrencyCode</label>
        <div class="col-sm-8">
          <mat-form-field class="w-100 bg-white" appearance="fill">
            <input type="text"
              class="form-control bg-white"
              [ngClass]="{'border-danger': (filteredCurrencies | async).length == 0}"
              [placeholder]="'userEdit.defaultCurrencyCode-placeholder' | translate"
              matInput
              [formControl]="currencies"
              [matAutocomplete]="auto2">
            <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
              <ng-container *ngFor="let item of filteredCurrencies | async">
                <mat-option  value="{{item.code}}">{{item.name}} - {{item.code}}</mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div> -->

    </ng-container>

    <div class="form-group align-right" *ngIf="company">
      <button mat-raised-button color="accent" class="btn cs-secondary-bt mx-3" mat-dialog-close>{{
        "userEdit.cancel-btn" | translate }}</button>
      <button
       mat-raised-button color="primary" class="btn cs-primary-btn mx-3" (click)="saveChangesForCompany()">{{
        "userEdit.save-btn" | translate}}</button>
    </div>
  </mat-tab>
</mat-tab-group>

import { map } from 'rxjs/operators';
import { APP_CONSTANTS } from './common/services/app-constants';
import { Component, Renderer2, Inject, OnDestroy, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { HeaderSidebarService } from './common/services/header-sidebar-service';
import { LanguageService } from './common/services/language-service';
import { RefresherService } from './common/services/refresher-service';
import { TextDirectionCtrlService } from './common/services/text-direction-ctrl-service';
import {  Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/common/services/auth-service';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionType, InteractionStatus, PopupRequest, RedirectRequest, AuthenticationResult, AuthError, InteractionRequiredAuthError, AuthenticationScheme } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { b2cPolicies, protectedResources } from './auth-config';
import { UserDataService } from 'src/app/common/services/user-data.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditprofileComponent } from './common/components/editprofile/editprofile.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsDataService } from './common/services/settings-data-service';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { ChatMsgDataService } from './common/services/chat-data-services';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit{
  unReadMessageCount = 0;
  isDevEnvironment = !environment.production;
  constructor(
    public headerSidebarService: HeaderSidebarService,
    public langService: LanguageService,
    private renderer: Renderer2,
    public refresherService: RefresherService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authMsalService: MsalService,
    public authService: AuthService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private userDataService: UserDataService,
    public matDialog: MatDialog,
    public settingDataService: SettingsDataService,
    private meta: Meta,
    private snackBar: MatSnackBar,
    private chatMsgDataService: ChatMsgDataService,
  ) {
    this.langService.init();
    this.renderer.setAttribute(document.body, 'dir', this.directionController.textDirection);
    // this.meta.addTag({
    //   property: 'og:url',
    //   content: environment.domain
    //    ,
    // });
    // this.meta.addTag({
    //   property: 'og:image',
    //   content: environment.domain+"assets/img/app-logo.png"
    //    ,
    // });
    this.chatMsgDataService.messageCount$.subscribe(() => {
      this.unReadMessageCount++;
    });
  }

  hello(){console.log()}
  @ViewChild('vr', {static: false}) public vr

  ngAfterViewInit(): void { }

  title = 'cs-portal';
  isIframe = false;
  loginDisplay = false;
  loggedInUser = {
    role: ''
  };
  private readonly _destroying$ = new Subject<void>();

  public directionController = new TextDirectionCtrlService();

  backendApiVersion: string;

  videoUrl = APP_CONSTANTS.TRAINING_VIDEO_URL;
  notesUrl = APP_CONSTANTS.NOTES_URL;
  language = this.langService.getCurrentLanguage()
  isExpress = false
  selectLanguage(lang){
    this.langService.setLanguage(lang)
    location.reload()
  }

  sidenavToggle() {
    this.headerSidebarService.sidenavSmall =
      !this.headerSidebarService.sidenavSmall;
  }

  refreshData() {
    RefresherService.fireRefreshEvt();
  }

  ngOnInit(): void {
    if( window.location.href.indexOf("express") > -1){
      this.isExpress = true
    }
    this.isIframe = window !== window.parent && !window.opener;

    this.userDataService.getAPIVersion().then((res: any) => {
      this.backendApiVersion = res.versionNumber;
    })

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log();
        const payload = result.payload as AuthenticationResult;
        this.authMsalService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
        this.setLoginDisplay();
      });
      const user = this.authService.getLoggedUser();
      this.chatMsgDataService.getUnreadMsgCount(user.customerId, user.userId).then((data)=>{
        this.unReadMessageCount = data.totalUnReadCount;
      });

  }

  checkFEversion() {
    let FE_Browser = this.vr.nativeElement.innerText
    this.settingDataService.GetLatestFERelease().then((data)=>{
      if (FE_Browser.toLowerCase() != data.releaseVersion.toLowerCase()) {
        const snack = this.snackBar
        // .open(this.langService.translateIt('nav.reloadMsg'), this.langService.translateIt('nav.reloadBtn'), {
        .open("A New Update Is Available Click To Reload","Reload", {
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        snack
          .onAction()
          .subscribe(() => {
            window.location.reload();
          })
      }
    })
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authMsalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authMsalService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authMsalService.instance.getAllAccounts();
      this.authMsalService.instance.setActiveAccount(accounts[0]);
    }
  }

  async setLoginDisplay() {
    this.loginDisplay = this.authMsalService.instance.getAllAccounts().length > 0;
    if (!this.loginDisplay) {
      this.login();
    }
    else{
      if(!this.authService.getAuthToken()){
        await this.getToken('GET');
      }
      this.createUser();
    }
  }

  checkIfDevicesAreActive(devices){
    let activeDevice = false
    for(let i=0; i<devices.length; i++){
      if(devices[i].deviceStatus && devices[i].deviceStatus.toLowerCase() == 'active')
        activeDevice = true
    }
    if(!activeDevice) this.router.navigateByUrl('/devices')
  }


  async createUser(){
      var loggedUser = this.authService.getLoggedUser();

      if (!loggedUser) {
        this.userDataService
          .CreateUserOnSignUp({
            username: this.authMsalService.instance.getActiveAccount().username,
            userEmail:
              this.authMsalService.instance.getActiveAccount()?.idTokenClaims[
              'emails'
              ][0],
            companyName:
              this.authMsalService.instance.getActiveAccount()?.idTokenClaims[
              'extension_Company'
              ],
            mobileNo: '',
            country: '',
            oAuthProvider:
              this.authMsalService.instance.getActiveAccount()?.idTokenClaims[
                'idp'
              ] ? this.authMsalService.instance.getActiveAccount()?.idTokenClaims[
              'idp'
              ] : "system",
          })
          .then((data) => {
            this.userDataService
              .getUserByEmail(
                this.authMsalService.instance.getActiveAccount()?.idTokenClaims[
                'emails'
                ][0]
              )
              .then((resUserData) => {
                this.authService.SetLoggedUser(resUserData);
                this.authService.changeUserState(resUserData);

                if(resUserData.role.toLowerCase() == 'admin') this.checkCompanyName(resUserData)

                if (resUserData.isNewCompanyUser) {
                  var customerId = this.authService.getLoggedUser().customerId;
                  this.userDataService
                    .getDeviceInfo(customerId)
                    .then((resDevice) => {
                      if (!resDevice.length) {
                        this.router.navigate(['/qr']);
                      }
                      if(resUserData.role.toLowerCase() == 'admin') this.checkIfDevicesAreActive(resDevice)
                      // else {
                      //   if (!resDevice[0].wainstanceUniqueKey) {
                      //     this.router.navigate(['/qr']);
                      //   }
                      // }
                    })
                }
              })
              .catch((err) =>{
                if(err.status == 400 ){
                  if(window.location.href.indexOf("express") == -1){
                    this.authMsalService.logout()
                    this.authService.signOutUser()
                  }

                  //  this.setLoginDisplay()
                }
              })
          })
          .catch((err) => {
            console.log();
            if (err.status === 409) {
              this.userDataService
                .getUserByEmail(
                  this.authMsalService.instance.getAllAccounts()[0][
                  'idTokenClaims'
                  ]['emails'][0]
                )
                .then((resUserData) => {
                  this.authService.SetLoggedUser(resUserData);
                  this.authService.changeUserState(resUserData);

                  if(resUserData.role.toLowerCase() == 'admin') this.checkCompanyName(resUserData)

                  if (resUserData.isNewCompanyUser) {
                    var customerId = this.authService.getLoggedUser().customerId;
                    this.userDataService
                      .getDeviceInfo(customerId)
                      .then((resDevice) => {
                        console.log()
                        if (!resDevice.length) {
                          this.router.navigate(['/qr']);
                        }
                        if(resUserData.role.toLowerCase() == 'admin') this.checkIfDevicesAreActive(resDevice)
                        // else {
                        //   if (!resDevice[0].wainstanceUniqueKey) {
                        //     this.router.navigate(['/qr']);
                        //   }
                        // }
                      })
                  }
                })
                .catch((err) =>{
                  if(err.status == 400 ){
                  if(window.location.href.indexOf("express") == -1){
                    this.authMsalService.logout()
                    this.authService.signOutUser()
                  }
                  // this.setLoginDisplay()
                  }
                })
            }
          });
      }
      else{
        this.authService.changeUserState(loggedUser);
      }
    }


  async getToken(method: string, query?: string) {
    const loginRequest = {
      scopes: [...protectedResources.todoListApi.scopes],
      authenticationScheme: AuthenticationScheme.POP,
      resourceRequestMethod: method,
      resourceRequestUri: query
        ? protectedResources.todoListApi.endpoint + query
        : protectedResources.todoListApi.endpoint,
    };

    return this.authMsalService
      .acquireTokenSilent({
        account: this.authMsalService.instance.getActiveAccount()
          ? this.authMsalService.instance.getActiveAccount()!
          : this.authMsalService.instance.getAllAccounts()[0]!,
        ...loginRequest,
      })
      .toPromise()
      .then((result) => {
        console.log();
        this.authService.setAuthToken(result.accessToken);
        return result.accessToken;
      })
      .catch((error) => {
        if (
          InteractionRequiredAuthError.isInteractionRequiredError(
            error.errorCode
          )
        ) {
          this.authMsalService
            .acquireTokenPopup(loginRequest)
            .toPromise()
            .then((result) => {
              this.authService.setAuthToken(result.accessToken);
              return result.accessToken;
            });
        }
      });
  }

  checkCompanyName(loggedUser){
    this.checkFEversion()
    this.userDataService.getCompanyInfo(loggedUser.customerId)
    .then((companyData) => {
      // console.log()
      const companyNameRegex = new RegExp(/^[^@]*$/);
          if (companyData && !companyNameRegex.test(companyData.organisationName)) {
            this.matDialog.open(EditprofileComponent, this.getDialogConfig(companyData))
          }

          if(loggedUser.role == 'Admin') this.router.navigateByUrl('/dashboard')
          // if(!companyData.isActive) this.router.navigateByUrl('/devices')

        })
  }

  getDialogConfig(params?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '95%';
    dialogConfig.width = '600px';
    dialogConfig.position = { right: '10px' };
    dialogConfig.data = params;
    dialogConfig.direction = this.langService.changeBrowserDirection( this.langService.getCurrentLanguage() )
    return dialogConfig;
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if(window.location.href.indexOf("express") == -1){
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        if (this.msalGuardConfig.authRequest) {
          this.authMsalService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
            .subscribe((response: AuthenticationResult) => {
              this.authMsalService.instance.setActiveAccount(response.account);
            });
        } else {
          this.authMsalService.loginPopup(userFlowRequest)
            .subscribe((response: AuthenticationResult) => {
              this.authMsalService.instance.setActiveAccount(response.account);
            });
        }
      } else {
        if (this.msalGuardConfig.authRequest) {
          this.authMsalService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
        } else {
          this.authMsalService.loginRedirect(userFlowRequest);
        }
      }
    }

  }

  logout() {
    if(window.location.href.indexOf("express") == -1){

    this.authMsalService.logout();
    }
  }

  editProfile() {
    let editProfileFlowRequest = {
      scopes: ["openid"],
      authority: b2cPolicies.authorities.editProfile.authority,
    };

    this.login(editProfileFlowRequest);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}

import { ExpressModule } from './components/express/express.module';
import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './common/services/auth-guard-service';
import { MsalGuard } from '@azure/msal-angular';
import { IsAdminGuard } from './common/services/is-admin.guard';
const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {
    path: 'qr',
    loadChildren: () =>
      import('./components/userlogin/login.component.module').then(
        (x) => x.LoginModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./components/dashboard/dashboard.routing.module').then(
        (x) => x.DashboardRoutingModule
      ),
    canActivate: [AuthGuardService,IsAdminGuard],
  },
  {
    path: 'campaigns',
    loadChildren: () =>
      import('./components/campaigns/campaigns-routing.module').then(
        (x) => x.CampaignRoutingModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'anniversary',
    loadChildren: () =>
      import('./components/anniversary/anniversary-routing.module').then(
        (x) => x.AnniversaryRoutingModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'template',
    loadChildren: () =>
      import('./components/Template/template-routing.module').then(
        (x) => x.TemplateModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'ai-bot',
    loadChildren: () =>
      import('./components/ai-bot/ai-bot.module').then(
        (x) => x.AiBotModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'messages',
    loadChildren: () =>
      import('./components/messages/messages-routing.module').then(
        (x) => x.MessagesRoutingModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'contacts',
    loadChildren: () =>
      import('./components/contacts/contacts-routing.module').then(
        (x) => x.ContactsRoutingModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'chats',
    loadChildren: () =>
      import('./components/chats/chat-routing.module').then(
        (x) => x.ChatsRoutingModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./components/users/users-routing.module').then(
        (x) => x.UsersRoutingModule
      ),
    canActivate: [AuthGuardService, IsAdminGuard],
  },
  {
    path: 'botrules',
    loadChildren: () =>
      import('./components/bot-rules/bot-rules-routing.module').then(
        (x) => x.BotRulesRoutingModule
      ),
    canActivate: [AuthGuardService, IsAdminGuard],
  },
  {
    path: 'backup',
    loadChildren: () =>
      import('./components/backup/backup-routing.module').then(
        (x) => x.BackupRoutingModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'devices',
    loadChildren: () =>
      import('./components/devices/devices-routing.module').then(
        (x) => x.DevicesRoutingModule
      ),
    canActivate: [AuthGuardService, IsAdminGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./components/settings/settings-routing.module').then(
        (x) => x.SettingsRoutingModule
      ),
    canActivate: [AuthGuardService, IsAdminGuard],
  },
  {
    path: 'express',
    loadChildren: () =>
      import('./components/express/express.module').then(
        (x) => x.ExpressModule
      )
  },
	{ path: '**', redirectTo: '/dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { ApiService } from './api-service';
import { APP_CONSTANTS } from './app-constants';
import { AppStorage } from './local-storage';
import { BehaviorSubject } from "rxjs";
@Injectable()
export class AuthService {
  // TOKEN
  constructor(private apiService: ApiService, private appStorage: AppStorage) { }
  private userState$ = new BehaviorSubject<any>({});
  userState = this.userState$.asObservable();
  changeUserState(myChange) {
    this.userState$.next(myChange);
  }
  getUserState() {
    return this.userState$.asObservable();
  }
  //   const
  URL_OBJ: string = 'user/Authenticate';
  URL_GET_USR: string = '/user/GetUserByUserEmail/';

  login(credentials: any): Promise<any> {
    return this.apiService
      .auth(this.URL_OBJ, credentials)
      .toPromise()
      .then(async (data) => {
        console.log();
        console.log();
        await this.saveLoginUser(data, credentials);
        return Promise.resolve(true);
      })
      .catch((error) => {
        console.log();

        if (
          error &&
          error.status == 401 &&
          error.statusText &&
          error.statusText == "Unauthorized"
        ) {
          return Promise.resolve(false);
        }

        return Promise.reject(false);
      });
  }

  saveLoginUser(response: any, credentials: any): Promise<boolean> {
    // this.appStorage.save(APP_CONSTANTS.STORAGE_LABELS.USER, response.body.user); // removed since user details unavail in response - temp
    this.appStorage.save(APP_CONSTANTS.STORAGE_LABELS.USER_TOKEN, response.tokens.value);

    var url = this.URL_GET_USR + credentials.useremail;
    return this.apiService.get(url).toPromise().then(data => {
      console.log();
      this.appStorage.save(APP_CONSTANTS.STORAGE_LABELS.USER, data);
      return Promise.resolve(true);
    });
  }

  signOutUser() {
    this.appStorage.remove(APP_CONSTANTS.STORAGE_LABELS.USER_TOKEN);
    this.appStorage.remove(APP_CONSTANTS.STORAGE_LABELS.USER);
  }

  rememberUser(username) {
    this.appStorage.save(APP_CONSTANTS.STORAGE_LABELS.REMEMBER_USER, username);
  }

  getRememberedUser() {
    var username = this.appStorage.get(APP_CONSTANTS.STORAGE_LABELS.REMEMBER_USER);
    if (username) {
      return username;
    }
    return undefined;
  }

  getLoggedUser() {
    var userObj = this.appStorage.get(APP_CONSTANTS.STORAGE_LABELS.USER);
    if (userObj) {
      return userObj;
    }
    return undefined;
  }

  getLoggedUserName() {
    var userObj = this.getLoggedUser();
    if (userObj) {
      return userObj.firstName + ' ' + userObj.lastName;
    }
    return undefined;
  }

  getTimeZone() {
    // return '+0000'; // custom time
    var usr = this.getLoggedUser();
    if (usr && usr.timeZone && usr.timeZone.length > 0) {
      var userTimezone = usr.timeZone.match(/\(GMT([^)]+)\)/)[1];
      return userTimezone;
    }

    return ''; // browser time
  }

  getOffsetDiffTime(originalDate: string): Date {
    var tzone = this.getTimeZone();
    // if no timezone set from profile return date itself
    if (tzone == '') {
      return new Date(originalDate);
    }

    tzone = tzone.replace(':', '.');
    var minsEdit = (parseInt(tzone.split('.')[1]) / 60) * 100;
    tzone = tzone.replace(tzone.split('.')[1], minsEdit.toString());

    var targetTime = new Date(originalDate);
    var tzDifference = parseFloat(tzone) * 60 + targetTime.getTimezoneOffset();
    return new Date(targetTime.getTime() - tzDifference * 60 * 1000);
  }

  getUserDefaultPageSize() {
    var usr = this.getLoggedUser();
    if (usr && usr.userProfileJson) {
      var prefJson = JSON.parse(usr.userProfileJson)
      if (prefJson && prefJson.ItemsPerPage) {
        return prefJson.ItemsPerPage;
      }
    }
    return 10;
  }

  isUserAdmin() {
    const ADMIN_VAL = 'Admin';
    var usr = this.getLoggedUser();
    return usr && usr.role && usr.role == ADMIN_VAL;
  }

  getAuthToken() {
    return this.appStorage.get(APP_CONSTANTS.STORAGE_LABELS.USER_TOKEN);
  }
  setAuthToken(token) {
    return this.appStorage.save(APP_CONSTANTS.STORAGE_LABELS.USER_TOKEN, token);
  }

  // setAuthToken(token) {
  //   return APP_CONSTANTS.STORAGE_LABELS.USER_TOKEN = token;
  // }

  // getAuthToken() {
  //   return APP_CONSTANTS.STORAGE_LABELS.USER_TOKEN;
  // }

  SetLoggedUser(account) {
    this.appStorage.save(APP_CONSTANTS.STORAGE_LABELS.USER, account);

  }

  getMaxFileSize(): Promise<any> {
    var url = '/storage/GetFileMaxSiz/getfilemaxsize';
    return this.apiService.get(url).toPromise().then(data => {
      return Promise.resolve(data);
    });
  }
}

import { UserMsgCounterService } from './../../services/user-msg-counter.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth-service';
import { ChangeuserpasswordComponent } from '../changeuserpassword/changeuserpassword.component';
import { EditprofileComponent } from '../editprofile/editprofile.component';
import { MsalService } from '@azure/msal-angular';
import { LanguageService } from '../../services/language-service';
// import { routes } from '../../../../consts';
// import { User } from '../../../../pages/auth/models';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent {
  constructor(public matDialog: MatDialog, private authService: AuthService, private router: Router, private authMsalService: MsalService, private userMsgCounterService: UserMsgCounterService, public langService: LanguageService) {
    this.user = this.authService.getLoggedUser();

    this.userMsgCounterService.eventEmitter.subscribe((counter) => {
      this.messageCounter = counter;
    });

  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.authService.getUserState().subscribe((data) => {
      this.user = data;
    });

  }
  user;
  messageCounter = 0;

  getMsgCounterTxt() {
    return (this.messageCounter > 99) ? "99+" : this.messageCounter;
  }

  editProfile() {
    this.matDialog.open(EditprofileComponent, this.getDialogConfig());
  }

  changePass() {
    this.matDialog.open(ChangeuserpasswordComponent, this.getDialogConfig());
  }

  getDialogConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '95%';
    dialogConfig.width = '600px';
    dialogConfig.position = { right: '10px' };
    dialogConfig.direction = this.langService.changeBrowserDirection( this.langService.getCurrentLanguage() )
    return dialogConfig;
  }


  signOutFn() {
    if(window.location.href.indexOf("express") == -1){

    this.authService.signOutUser();
    this.authMsalService.logout();
    }
  }
}

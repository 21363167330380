<div class="profile-div">
  <mat-label *ngIf="user" class="user-name">
    {{ user.firstName }} {{ user.lastName }}
  </mat-label>

  <button class="user-button" mat-mini-fab [matMenuTriggerFor]="userMenu">
    <mat-icon class="user-button__icon">
      <img class="cs-icon-primary" src="assets/img/icon-pack/icon-edit-pro.svg">
    </mat-icon>
  </button>
  <mat-menu class="top-right-user-menu" #userMenu="matMenu" xPosition="before">
  
    <div class="row user-menu-title" *ngIf="user">
      <!-- <img [src]="/src/assets/img/login.png"> -->
      <div class="col-md-3 user-avatar-box">
        <img class="user-avatar" src="assets/img/avatar_1.png" />
      </div>
      <div class="col-md-9">
        <h4 class="user-menu-title__name">{{ user.firstName }} {{ user.lastName }}</h4>
        <h5 class="user-menu-title__role">{{ user.role }}</h5>
        <div class="user-menu-title__actions">
          <button mat-menu-item class="user-menu__item-title" (click)="editProfile()">
            <!-- <img class="cs-icon-primary" src="assets/img/icon-pack/icon-edit-pro.svg"> -->
            {{ "userPopup.edit-profile" | translate }}
          </button>
          <button mat-menu-item class="user-menu__item-title" (click)="changePass()"
            *ngIf="user && user.oauth == false && user.oauthProvider == 'system'">
            <!-- <img class="cs-icon-primary" src="assets/img/icon-pack/icon-pwd-chng.svg"> -->
            {{ "userPopup.change-pwd" | translate }}
          </button>
          <button class="signout-button" mat-flat-button (click)="signOutFn()">
            {{ "userPopup.signout-btn" | translate }}
          </button>
        </div>
      </div>
    </div>
  </mat-menu>
</div>